import { Component, OnInit } from '@angular/core';
import { PacienteService } from '../_services/paciente.service';
import {
  UntypedFormGroup,
  Validators,
  UntypedFormBuilder,
  FormControl,
} from '@angular/forms';
import { SignosVitalesEvo } from '../_models/signosVitalesEvo.model';
import { EvolucionService } from '../_services/evolucion.service';
import { AlertService } from '../_services/alert.service';
import { MedicacionService } from '../_services/medicacion.service';
import { Observable, Subscription, of } from 'rxjs';
import { toTitleCasePipe } from '../_pipes/toTitleCase.pipe';
import { EnfermeriaEvo } from '../_models/enfermeriaEvo.model';
import { getLocaleDateTimeFormat } from '@angular/common';
import { AuthenticationService } from '../_services/authentication.service';
import { delay } from 'rxjs/operators';
import { toLocalDatePipe } from '../_pipes/toLocalDate.pipe';
import { UrlResolverService } from '../_services/urlResolver.service';
import { BalanceService } from '../_services/balance.service';
import { Concepto } from '../_models/concepto.model';
import {
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from '@angular/material-moment-adapter';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import { Moment } from 'moment';
import { AnonymousSubject } from 'rxjs/internal/Subject';
import * as moment from 'moment';
import { ControlDeInfeccionesEvo } from '../_models/controDeInfeccionesEvo.model';
import { PedidosService } from '../_services/pedidos.service';
import { DialogConfirmacionComponent } from '../dialog-confirmacion/dialog-confirmacion.component';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { connectableObservableDescriptor } from 'rxjs/internal/observable/ConnectableObservable';
import { Router } from '@angular/router';
//import { ThrowStmt } from '@angular/compiler';
import { DialogMensajeComponent } from '../dialog-mensaje/dialog-mensaje.component';
import { DataSource } from '@angular/cdk/collections';
import { CharactersService } from '../_services/characters.service';
import { InternacionService } from '../_services/internacion.service';

@Component({
  selector: 'app-paciente',
  templateUrl: './paciente.component.html',
  styleUrls: ['./paciente.component.css'],
  providers: [
    toTitleCasePipe,
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ],
})
export class PacienteComponent implements OnInit {
  
  currentPatient;
  currentUser;
  registroSeleccionado;

  evolucionEnfermeriaForm: UntypedFormGroup;
  loadingEvolucionEnfermeria = false;
  submittedEvolucionEnfermeria = false;
  opcionesOxigeno = ['mascara', 'canula'];
  datePickerDisabled = true;

  registroPerdidoForm: UntypedFormGroup;
  loadingRegistroPerdido = false;
  submittedRegistroPerdido = false;
  desplegarRegistroPerdido = false;

  medicamentoElegido;
  medicamentos = [];

  signosVitalesForm: UntypedFormGroup;
  loadingSignosVitales = false;
  submittedSignosVitales = false;

  loadingIndicacion = true;
  indicacion: any;
  items: any;
  planes: any = [];
  groups: UntypedFormGroup[] = [];
  grouped;
  loadingReg: any[] = [];
  selectedUrl: any;
  selected = false;
  horusUrl: any;

  loadingConceptos = false;
  conceptos: Concepto[];
  conceptosIngresos: Concepto[];
  conceptosEgresos: Concepto[];

  ingresosEgresosForm: UntypedFormGroup;
  loadingIngresosEgresos = false;

  maxDate: Date;
  fechaSeleccionada;
  groupsPlanes: UntypedFormGroup[] = [];
  etiquetasPaciente: any;
  groupedPlans: any[];
  jsonPedidoDxI: any = {};
  jsonCirugia: any = {};
  listaPedidosDxI: any[] = [];
  listaCirugiasProg: any[] = [];
  arregloHistorial: any[] = [];

  listaRegistrosMedicamentos;
  // verUlt24hs = false;
  errorConexion = false;
  errorConexion2 = false;
  escalaRass = [
    { value: -5, descripcion: 'No estimulable' },
    { value: -4, descripcion: 'Sedación profunda' },
    { value: -3, descripcion: 'Sedación moderada' },
    { value: -2, descripcion: 'Sedación ligera' },
    { value: -1, descripcion: 'Somnoliento' },
    { value: 0, descripcion: 'Alerta y tranquilo' },
    { value: 1, descripcion: 'Inquieto' },
    { value: 2, descripcion: 'Agitado' },
    { value: 3, descripcion: 'Muy agitado' },
    { value: 4, descripcion: 'Combativo' },
  ];

  escalaCamicu = [
    { value: 1, descripcion: 'Comienzo agudo o curso fluctuante' },
    { value: 2, descripcion: 'Disminución de la atención' },
    { value: 3, descripcion: 'Alteración de la conciencia' },
    { value: 4, descripcion: 'Alteraciones cognitivas' },
  ];

  modosVentilatorios = ['VCV', 'PCV', 'PSV', 'VNI', 'T/T'];
  datePickerDisabledARM = true;
  cantidadSolicitudes: number = 0;
  expanded: boolean;
  esAyer: boolean;
  groupedListaRegistrosMed;
  groupsListaRegMed: UntypedFormGroup[] = [];

  //Variables que usa el slide toggle de UPP
  tieneUPP: false; //Variable para saber si fué chequeado o no
  gradovalores = [
     "1"  ,
     "2"  ,
     "3"  ,
     "4"  ,
  ];
  //modificacion de upp
  otroValor = false;
  otraZona:String;
  zonaAux :String;
  listaZonaAux: any[] = [];
  listaZonas: any = [
    { value: 'Sacro' } ,
    { value: 'Talones' } ,
    { value: 'Occipital' } ,
    { value: 'Miembros Superiores' } ,
    { value: 'Miembros Inferiores' } ,
    { value: 'Gluteos' } ,
    { value: 'Otros' }
  ]
  listaUPP = [];
  listaUPPmostrar;
  columnasUpp = ['zona', 'grado', 'riesgo', 'accion'];
  uppForm: UntypedFormGroup;
  //checkbox
  valoresCheck: any = [
    {desc:'Si' , value: true},
    {desc:'No', value: false},
    {desc:'N/A', value: null}

  ]
  listaViasUbicacion = ['YAD', 'YAI', 'YPD', 'YPI', 'SCD', 'SCI', 'FD', 'FI', 'CYSCD', 'CYSCI'];
  idSeleccionado:string;
  seleccionado:number;
  click = 0;
  tieneEstadoIngresoHabitacion:boolean;
  estadoIngresoHabitacion:string;
  cirugiasHoy:any[];
  cargandoIngreso:boolean = false;


  constructor(
    private pacienteService: PacienteService,
    private formBuilder: UntypedFormBuilder,
    private evolucionService: EvolucionService,
    private medicacionService: MedicacionService,
    private tcPipe: toTitleCasePipe,
    private alertService: AlertService,
    private balanceService: BalanceService,
    private authenticationService: AuthenticationService,
    private urlResolver: UrlResolverService,
    private _dateAdapter: DateAdapter<any>,
    private pedidosService: PedidosService,
    public dialog: MatDialog,
    private router: Router,
    private characterService:CharactersService,
    private internacionService: InternacionService
  ) {
    this.maxDate = new Date();

    this.pacienteService.currentPatient.subscribe((x) => {
      this.currentPatient = x;
      this.jsonPedidoDxI.nroDniPaciente = this.currentPatient.dniPaciente;
      this.jsonPedidoDxI.tipoDniPaciente = this.currentPatient.tipoDniPaciente;
      this.jsonCirugia.nroDniPaciente = this.currentPatient.dniPaciente;
      this.jsonCirugia.tipoDniPaciente = this.currentPatient.tipoDniPaciente;
      this.jsonCirugia.fechaDesde = new Date().toLocaleDateString();
      // console.log(this.jsonCirugia);      
    });

    this.authenticationService.currentUser.subscribe((x) => {
      this.currentUser = x;
    });

    this.urlResolver.getHorusfullUrl().subscribe((x) => {
      this.horusUrl = x.paginador.elementos[0].valor;
      // console.log(this.horusUrl);
    });

   // this.cargarMedicamentosIndicados();
   
  }

  cargarMedicamentosIndicados() {
    this.medicacionService
      .historialIndicaciones(this.currentPatient.carpeta)
      .subscribe((x) => {
        x.paginador.elementos.forEach((pedido) => {
          pedido.itemsPedido.forEach((item) => {
            this.medicamentos.push(item);
          });
        });
        this.medicamentos = this.medicamentos.filter((x) => x.planId === 0);
       // console.log(this.medicamentos);
      });
  }

  ngOnInit() {
    this._dateAdapter.setLocale('es_AR');
    

    this.pacienteService
      .datosPaciente(
        this.currentPatient.dniPaciente,
        this.currentPatient.tipoDniPaciente
      )
      .subscribe(
        (data) => {
          this.pacienteService.getEtiquetaPaciente(data).subscribe(
            (etiquetasPaciente) => {
              this.etiquetasPaciente = etiquetasPaciente.paginador.elementos;
            },
            (error) => {
              console.log(error);
            }
          );
        },
        (error) => {
          console.log(error);
        }
      );

    this.cargarConceptosDeBalance();

    this.ingresosEgresosForm = this.formBuilder.group({
      concepto: [null, [Validators.required]],
      volumen: ['', [Validators.required, Validators.pattern('[0-9]*')]],
      observaciones: [null],
    });

    this.evolucionEnfermeriaForm = this.formBuilder.group({
      toleranciaOral: [false],
      tto_vo: [false],
      avp: [false],
      deambulacion: [false],
      heridaQx: [false],
      lugarHeridaQx: [{ value: null, disabled: true }],
      peritaltismo: [false],
      evolucion: [null],
      dolor: [null],
      estado: [null],
      otros: [null],
      upp: [false],
      //zona: [{ value: null, disabled: true }],
      //grado: [{ value:null , disabled: true }],
      //riesgo: [{ value: null, disabled: true },[Validators.pattern('^[A-Za-zñÑáéíóúÁÉÍÓÚ0-9_.+-]+[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')]],
      sv: [null],
      lavado: [false],
      sng: [null,[Validators.pattern('[a-zA-Z0-9 ]*')]],
      k108: [null,[Validators.pattern('[a-zA-Z0-9 ]*')]],
      so: [null,[Validators.pattern('[a-zA-Z0-9 ]*')]],
      sg: [null,[Validators.pattern('[a-zA-Z0-9 ]*')]],
      habilitarFechaTraqueotomia: [false],
      fechaTraqueotomia: [{ value: null, disabled: true }],
      oxigeno: [null],
      habilitarFechaARM: [false],
      fechaARM: [{ value: null, disabled: true }],
      modoVentilatorio: [null],
      vt: [null,[Validators.pattern('[a-zA-Z0-9 ]*')]],
      peep: [null, [Validators.pattern('[a-zA-Z0-9 ]*')]],
      pi: [null,[Validators.pattern('[a-zA-Z0-9 ]*')]],
      fio2: [null,[Validators.pattern('[a-zA-Z0-9 ]*')]],
      higieneManos: [null],
      cabeceraElevada: [null],
      controlPresionCuff: [null],
      observacionPresionCuff: [{ value: null, disabled: true }],
      listaUPP: [[]],
      higieneBucal: [null],
      aspiracionSubglotica: [null],
      suspensionSedacion:[null],
      avc: [false],  // booleano
      cateterDialisis: [false],
      dataAvc: [null], // lo que se manda al back
      dataCateterDialisis: [null],
      formAvc: [[]], // Lo que se carga en el form
      formCateterDialisis: [[]]
    }); 

    this.uppForm = this.formBuilder.group({
      //upp: [false],
      zona: [{ value: null, disabled: true }, [Validators.required] ],
      grado: [{ value:null , disabled: true}, [Validators.required] ],
      riesgo: [{ value: null, disabled: true },[Validators.pattern('[a-zA-Z0-9 ]*')]],
    })

    this.evolucionEnfermeriaForm
      .get('controlPresionCuff')
      .valueChanges.subscribe((value) => {
        if (value) {
          this.evolucionEnfermeriaForm.get('observacionPresionCuff').enable();
        } else {
          this.evolucionEnfermeriaForm.get('observacionPresionCuff').disable();
          this.evolucionEnfermeriaForm.get('observacionPresionCuff').reset();
        }
      });

    this.evolucionEnfermeriaForm
      .get('heridaQx')
      .valueChanges.subscribe((value) => {
        if (value) {
          this.evolucionEnfermeriaForm.get('lugarHeridaQx').enable();
        } else {
          this.evolucionEnfermeriaForm.get('lugarHeridaQx').disable();
          this.evolucionEnfermeriaForm.get('lugarHeridaQx').reset();
        }
      });
    this.evolucionEnfermeriaForm
      .get('habilitarFechaTraqueotomia')
      .valueChanges.subscribe((value) => {
        if (value) {
          this.datePickerDisabled = false;
          this.evolucionEnfermeriaForm.get('fechaTraqueotomia').enable();
          this.evolucionEnfermeriaForm
            .get('fechaTraqueotomia')
            .setValidators(Validators.required);
          this.evolucionEnfermeriaForm.get('fechaTraqueotomia').reset();
        } else {
          this.datePickerDisabled = true;
          this.evolucionEnfermeriaForm.get('fechaTraqueotomia').disable();
          this.evolucionEnfermeriaForm
            .get('fechaTraqueotomia')
            .setValidators(null);
          this.evolucionEnfermeriaForm.get('fechaTraqueotomia').reset();
        }
        this.evolucionEnfermeriaForm.updateValueAndValidity();
      });

    this.evolucionEnfermeriaForm
      .get('habilitarFechaARM')
      .valueChanges.subscribe((value) => {
        if (value) {
          this.datePickerDisabledARM = false;
          this.evolucionEnfermeriaForm.get('fechaARM').enable();
          this.evolucionEnfermeriaForm
            .get('fechaARM')
            .setValidators(Validators.required);
          this.evolucionEnfermeriaForm.get('fechaARM').reset();
        } else {
          this.datePickerDisabledARM = true;
          this.evolucionEnfermeriaForm.get('fechaARM').disable();
          this.evolucionEnfermeriaForm.get('fechaARM').setValidators(null);
          this.evolucionEnfermeriaForm.get('fechaARM').reset();
        }
        this.evolucionEnfermeriaForm.updateValueAndValidity();
      });

    this.signosVitalesForm = this.formBuilder.group({
      frecuenciaCardiaca: [null],
      frecuenciaRespiratoria: [null],
      hemoglucotest: [null],
      saturacion: [null],
      temperatura: [null, [Validators.pattern('[0-9]+(?:\\.[0-9]+)?')]],
      tensionArterial: [null],
      rass: [null],
      camicu: [null],
    });

    this.medicacionService
      .indicacionActual(this.currentPatient.carpeta)
      .subscribe(
        (x) => {
         // this.loadingIndicacion = false;

          if (x.paginador.elementos.length > 0) {
            this.indicacion = x.paginador.elementos[0];
            this.planes = x.paginador.elementos[0].itemsPedido.filter(
              (elem) => elem.planId !== 0
            );
            this.items = x.paginador.elementos[0].itemsPedido.filter(
              (elem) => elem.planId === 0
            );
            this.planes.forEach((element, index) => {
              this.groupsPlanes.push(
                this.formBuilder.group({
                  idRegistro: [element.idRegistro],
                  numeroPedido: [element.numeroPedido],
                  numeroItem: [element.numeroItem],
                  fechaUsuario: [
                    this.getFormTime(new Date()),
                    [Validators.required],
                  ],
                  nomenclador: [element.nomenclador],
                  practica: [element.practica.codigo],
                  descripcion: [
                    this.tcPipe.transform(element.practica.descripcion),
                    [Validators.required],
                  ],
                  frecuencia: [
                    this.tcPipe.transform(element.frecuencia.frecuencia),
                  ],
                  goteo: [element.goteo.goteo],
                  ultimoRegistro: [element.ultimoRegistro],
                  ultimoEnfermero: [element.enfermero],
                  indiAdicional: [element.indicacionesAdicionales],
                  via: [
                    this.tcPipe.transform(element.via.via),
                    [Validators.required],
                  ],
                  cantidad: [
                    element.cantSolicitada,
                    [
                      Validators.required,
                      Validators.pattern(/^-?(0|[1-9]\d*)?$/),
                    ],
                  ],
                  ayer: [false],
                  planId: [element.planId],
                })
              );
            });
            this.items.forEach((element, index) => {
              this.groups.push(
                this.formBuilder.group({
                  idRegistro: [element.idRegistro],
                  numeroPedido: [element.numeroPedido],
                  numeroItem: [element.numeroItem],
                  fechaUsuario: [
                    this.getFormTime(new Date()),
                    [Validators.required],
                  ],
                  nomenclador: [element.nomenclador],
                  practica: [element.practica.codigo],
                  descripcion: [
                    this.tcPipe.transform(element.practica.descripcion),
                    [Validators.required],
                  ],
                  frecuencia: [
                    this.tcPipe.transform(element.frecuencia.frecuencia),
                  ],
                  goteo: [element.goteo.goteo],
                  ultimoRegistro: [element.ultimoRegistro],
                  ultimoEnfermero: [element.enfermero],
                  indiAdicional: [element.indicacionesAdicionales],
                  via: [
                    this.tcPipe.transform(element.via.via),
                    [Validators.required],
                  ],
                  cantidad: 1,
                  // cantidad: [
                  //   element.cantSolicitada,
                  //   [
                  //     Validators.required,
                  //     Validators.pattern(/^-?(0|[1-9]\d*)?$/),
                  //   ],
                  // ],
                  ayer: [false],
                  ult24hs: [],
                  verUlt24hs: [false],
                })
              );
            });
          }
          this.grouped = this.groupBy(this.groups, (item) => {
            return [item.value.via];
          });
          this.groupedPlans = this.groupBy(this.groupsPlanes, (item) => {
            return [item.value.planId];
          });
          this.cargarRegistrosMedicamentosUlt24hs();
        },
        (error) => {
          console.log(error);
        }
      );
    this.registroPerdidoForm = this.formBuilder.group({
      medicamento: ['', Validators.required],
      fechaUsuario: ['', Validators.required],
    });

    this.cargarPedidosCirugias();
    this.cargarEstadoIngresoHabitacion();
  }

  cargarRegistrosMedicamentosUlt24hs() {
    const fechaHas = new Date();
    const fechaDes = new Date(fechaHas.getFullYear(),
                                fechaHas.getMonth(),
                                (fechaHas.getDate() - 1),
                                fechaHas.getHours(),
                                fechaHas.getMinutes(),
                                fechaHas.getSeconds());
    const fechaHasta = moment(fechaHas).format('MM-DD-YYYY HH:mm:ss');
    const fechaDesde = moment(fechaDes).format('MM-DD-YYYY HH:mm:ss');
    this.medicacionService
      .getRegistrosMedicamentos(this.currentPatient.carpeta, fechaDesde, fechaHasta)
      .subscribe((resp) => {
        if (resp.paginador.elementos.length > 0) {
          this.listaRegistrosMedicamentos = resp.paginador.elementos;
          this.listaRegistrosMedicamentos = resp.paginador.elementos.filter(
            (elem) => elem.planId === ''
          );
          this.listaRegistrosMedicamentos.forEach((element, index) => {
            this.groupsListaRegMed.push(
              this.formBuilder.group({
                idRegistro: [element.idRegistro],
                numeroPedido: [element.numeroPedido],
                numeroItem: [element.numeroItem],
                fechaUsuario: [element.fechaUsuario],
                nomenclador: [element.nomenclador],
                practica: [element.practica],
                descripcion: [
                  this.tcPipe.transform(element.descripcion),
                ],
                goteo: [element.goteo],
                enfermero: [element.enfermero],
                cantidad: [element.cantidad],
                planId: [element.planId],
                idCarpeta: [element.idCarpeta],
                numeroDocumento: [element.numeroDocumento],
                tipoDocumento: [element.tipoDocumento],
                obs: [element.obs]

              })
            );
          });
        }
        this.groupedListaRegistrosMed = this.groupBy(this.groupsListaRegMed, (reg) => {
          return [reg.value.practica];
        });
        /* agrega el historial a cada medicacion */
        this.grouped.forEach(via => {
          via.forEach(medicamento => {
            const formHistorial = this.groupedListaRegistrosMed.filter(
            (histMed) => (parseInt(histMed[0].value.practica, 10) ===
                          parseInt(medicamento.value.practica, 10))
            );
            this.arregloHistorial = [];
            if (formHistorial[0]) {
              formHistorial[0].forEach(element => {
                /*unshift es lo mismo que push pero inserta adelante*/
                 this.arregloHistorial.unshift({
                   cantidad: element.value.cantidad,
                   descripcion: element.value.descripcion,
                   enfermero: element.value.enfermero,
                   fechaUsuario: element.value.fechaUsuario,
                   idRegistro: element.value.idRegistro.toString(),
                   practica: element.value.practica,
                 });
               });
            }
            this.arregloHistorial = this.arregloHistorial.sort((a, b) => {
                if (a.fechaUsuario > b.fechaUsuario) {
                  return -1;
                } else {return 1; }
            });

            const sinUltReg = this.arregloHistorial.filter((obj) => obj.idRegistro !== medicamento.value.idRegistro);
            if (sinUltReg.length) {
              medicamento.get('ult24hs').setValue(sinUltReg);
            } else {
              medicamento.get('ult24hs').setValue([{fechaUsuario: ''}]);
            }
          });
        });
        this.loadingIndicacion = false;
      });
  }

  cargarUlt24hsPorMedicamento(practica) {
    const fechaHas = new Date();
    const fechaDes = new Date(fechaHas.getFullYear(),
                                fechaHas.getMonth(),
                                (fechaHas.getDate() - 1),
                                fechaHas.getHours(),
                                fechaHas.getMinutes(),
                                fechaHas.getSeconds());
    const fechaHasta = moment(fechaHas).format('MM-DD-YYYY HH:mm:ss');
    const fechaDesde = moment(fechaDes).format('MM-DD-YYYY HH:mm:ss');
    this.medicacionService
      .getRegistrosMedicamentos(this.currentPatient.carpeta, fechaDesde, fechaHasta)
      .subscribe((resp) => {
        if (resp.paginador.elementos.length > 0) {
          this.listaRegistrosMedicamentos = resp.paginador.elementos;
          this.listaRegistrosMedicamentos = resp.paginador.elementos.filter(
            (elem) => elem.planId === ''
          );
          this.groupsListaRegMed = [];
          this.listaRegistrosMedicamentos.forEach((element, index) => {
            this.groupsListaRegMed.push(
              this.formBuilder.group({
                idRegistro: [element.idRegistro],
                numeroPedido: [element.numeroPedido],
                numeroItem: [element.numeroItem],
                fechaUsuario: [element.fechaUsuario],
                nomenclador: [element.nomenclador],
                practica: [element.practica],
                descripcion: [
                  this.tcPipe.transform(element.descripcion),
                ],
                goteo: [element.goteo],
                enfermero: [element.enfermero],
                cantidad: [element.cantidad],
                planId: [element.planId],
                idCarpeta: [element.idCarpeta],
                numeroDocumento: [element.numeroDocumento],
                tipoDocumento: [element.tipoDocumento],
                obs: [element.obs]

              })
            );
          });
        }
        this.groupedListaRegistrosMed = this.groupBy(this.groupsListaRegMed, (reg) => {
          return [reg.value.practica];
        });

        const historialPractica = this.groupedListaRegistrosMed.filter(
          (histMed) => (parseInt(histMed[0].value.practica, 10) ===
                        parseInt(practica, 10))
          );

        this.arregloHistorial = [];
        if (historialPractica[0]) {
          historialPractica[0].forEach(element => {
            this.arregloHistorial.unshift({
              cantidad: element.value.cantidad,
              descripcion: element.value.descripcion,
              enfermero: element.value.enfermero,
              fechaUsuario: element.value.fechaUsuario,
              idRegistro: (element.value.idRegistro).toString(),
              practica: element.value.practica,
            });
          });
        }
        this.arregloHistorial = this.arregloHistorial.sort((a, b) => {
          if (a.fechaUsuario > b.fechaUsuario) {
            return -1;
          } else {return 1; }
        });
      });
    return this.arregloHistorial;
  }

  async cargarPedidosCirugias() {
    this.jsonPedidoDxI.estado = 'SOL';
    this.jsonPedidoDxI.tipoPedido = 1;
    this.jsonPedidoDxI.sectorInforme = 1;

    await this.cargarPedidosDxI(); // CARGO LOS PEDIDOS SOLICITADOS
    await this.setEstadoPedido('PRO');
    await this.cargarPedidosDxI(); // CARGO LOS PEDIDOS PROGRAMADOS
    await this.cargarCirugias();
  }

  async setEstadoPedido(estado) {
    this.jsonPedidoDxI.estado = estado;
  }

  async cargarPedidosDxI() {
    await this.pedidosService
      .getPedidosGenerico(this.jsonPedidoDxI)
      .toPromise()
      .then(
        (respGetPedidos) => {
          // console.log(respGetPedidos);
          if (respGetPedidos) {
            if (respGetPedidos.ok) {
              this.cantidadSolicitudes =
                this.cantidadSolicitudes + respGetPedidos.pedidos.length;
              // console.log(this.cantidadSolicitudes);
              respGetPedidos.pedidos.forEach((element) => {
                if (element.categoria == 'INTERNADO' && element.nroCarpeta == this.currentPatient.carpeta) {
                  this.listaPedidosDxI.push(element);
                }
              });
            }
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }

  async cargarCirugias() {
    this.pedidosService.getCirugiasGenerico(this.jsonCirugia).subscribe(
      (respGetCirugias) => {
        // console.log(respGetCirugias);
        if (respGetCirugias.ok) {
          this.cantidadSolicitudes =
            this.cantidadSolicitudes + respGetCirugias.reservas.length;
          this.listaCirugiasProg = respGetCirugias.reservas;
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }

  submitIngresosEgresos() {
    this.loadingIngresosEgresos = true;
    if (this.ingresosEgresosForm.invalid) {
      this.loadingIngresosEgresos = false;
      return;
    }
    this.ingresosEgresosForm.value.carpeta = {
      carpeta: this.currentPatient.carpeta,
    };
    this.ingresosEgresosForm.value.usuarioRegistro = this.currentUser.usuario;
    this.balanceService.registrar(this.ingresosEgresosForm.value).subscribe(
      (x) => {
        this.cargarRegistrosDeConcepto(
          this.conceptos.find(
            (concepto) =>
              concepto.idConcepto ===
              this.ingresosEgresosForm.value.concepto.idConcepto
          ),
          this.currentPatient.carpeta
        );
        this.loadingIngresosEgresos = false;
        this.ingresosEgresosForm.reset();
      },
      (error) => {
        this.loadingIngresosEgresos = false;
        console.log(error);
        this.ingresosEgresosForm.reset();
      }
    );
  }

  suministroUnicaVez(formMed) {
    // formMed.frecuencia == 'U/V'
    const fechaPedida = moment(
      this.indicacion.fechaPedida,
      'DD/MM/YYYY HH:mm:ss A'
    ).toDate();
    return (
      formMed.value.frecuencia === 'U/v' &&
      fechaPedida >
        moment(formMed.value.ultimoRegistro, 'DD/MM/YYYY HH:mm').toDate()
    );
  }

  javaFormat(date: Moment) {
    // console.log(date)
    if (date != null) {
      const prueba = new Date(date.year(), date.month(), date.date(), 0, 0, 0);

      return (
        prueba.toLocaleString('en-US', { month: 'short' }) + ' ' + date.format('DD, YYYY, hh:mm:ss A')
      );
    }
  }

  getFormTime(dt) {
    return (
      (dt.getHours() < 10 ? '0' : '') +
      dt.getHours() +
      ':' +
      (dt.getMinutes() < 10 ? '0' : '') +
      dt.getMinutes()
    );
  }

  arrayFromObject(obj) {
    const arr = [];
    for (const i in obj) {
      arr.push(obj[i]);
    }
    return arr;
  }

  groupBy(list, fn) {
    const groups = {};
    for (let i = 0; i < list.length; i++) {
      const group = JSON.stringify(fn(list[i]));
      if (group in groups) {
        groups[group].push(list[i]);
      } else {
        groups[group] = [list[i]];
      }
    }
    return this.arrayFromObject(groups);
  }

  get sv() {
    return this.signosVitalesForm.controls;
  }
  get evo() {
    return this.evolucionEnfermeriaForm.controls;
  }

  dateFormat(date: Date) {   

    return (
      date.getDate() + '/' + (date.getMonth()+1) + '/' + date.getFullYear() 
      + ' ' +
      date.getHours() + ':' + date.getMinutes() + ':' + date.getSeconds()
    )
  }

  submitSv() {
    this.submittedSignosVitales = true;
    this.loadingSignosVitales = true;
    if (this.signosVitalesForm.invalid) {
      this.alertService.error('Hubo un error, revise los campos');
      this.loadingSignosVitales = false;
      setTimeout(() => {
        this.alertService.clear();
      }, 5000);
      return;
    }

    Object.keys(this.signosVitalesForm.controls).forEach((control) => {
      if (this.signosVitalesForm.get(control).value === '') {
        this.signosVitalesForm.get(control).reset();
      }
    });
    const sv = new SignosVitalesEvo(this.signosVitalesForm.value);
    this.setDatosGenerales(sv);

    this.evolucionService.enviarSignosVitales(sv).subscribe(
      (x) => {
        this.loadingSignosVitales = false;
        this.signosVitalesForm.reset();
        this.alertService.success('Signos vitales cargados correctamente');
      },
      (error) => {
        console.log(error);
        this.alertService.error('Se produjo un error, intente mas tarde');
      }
    );

    setTimeout(() => {
      this.alertService.clear();
    }, 5000);

    this.alertService.clear();
  }

  resetSv() {
    this.signosVitalesForm.reset();
  }

  escaparCaracteres(form) {
    // console.log(form)
    form.evolucionEnfermeria.observacionPresionCuff = this.characterService.escaparCaracteresEspeciales(form.evolucionEnfermeria.observacionPresionCuff);
    form.evolucionEnfermeria.evolucion = this.characterService.escaparCaracteresEspeciales(form.evolucionEnfermeria.evolucion);
  }

  submitEvo() {
    this.submittedEvolucionEnfermeria = true;
    if (this.evolucionEnfermeriaForm.invalid) {
      this.loadingEvolucionEnfermeria = false;
      this.alertService.error('Hubo un error, revise los campos');
      setTimeout(() => {
        this.alertService.clear();
      }, 5000);
     // console.log('invalido');
      return;
    }

    this.evolucionEnfermeriaForm.value.fechaTraqueotomia = this.javaFormat(this.evolucionEnfermeriaForm.value.fechaTraqueotomia);
    this.evolucionEnfermeriaForm.value.fechaARM = this.javaFormat(this.evolucionEnfermeriaForm.value.fechaARM);
  
    if (this.evolucionEnfermeriaForm.value.upp) {
      this.evolucionEnfermeriaForm.value.listaUPP = this.listaUPP;
      if (this.listaUPP.length == 0) { // cambio si tiene upp o no en caso de que haya una lista vacia
        this.evolucionEnfermeriaForm.controls.upp.setValue(false); 
      }
    } 

    if (this.evolucionEnfermeriaForm.value.avc) {
      const datosAvc = {
        tipoVia: "avc",
        yad: this.evolucionEnfermeriaForm.value.formAvc.includes('YAD'),
        yai: this.evolucionEnfermeriaForm.value.formAvc.includes('YAI'),
        ypd: this.evolucionEnfermeriaForm.value.formAvc.includes('YPD'),
        ypi: this.evolucionEnfermeriaForm.value.formAvc.includes('YPI'),
        scd: this.evolucionEnfermeriaForm.value.formAvc.includes('SCD'),
        sci: this.evolucionEnfermeriaForm.value.formAvc.includes('SCI'),
        fd: this.evolucionEnfermeriaForm.value.formAvc.includes('FD'),
        fi: this.evolucionEnfermeriaForm.value.formAvc.includes('FI'),
        cyscd: this.evolucionEnfermeriaForm.value.formAvc.includes('CYSCD'),
        cysci: this.evolucionEnfermeriaForm.value.formAvc.includes('CYSCI'),
      };
      this.evolucionEnfermeriaForm.controls.dataAvc.setValue(datosAvc);
    }
    if (this.evolucionEnfermeriaForm.value.cateterDialisis) {
      const datosCpd = {
        tipoVia: "cateterDialisis",
        yad: this.evolucionEnfermeriaForm.value.formCateterDialisis.includes('YAD'),
        yai: this.evolucionEnfermeriaForm.value.formCateterDialisis.includes('YAI'),
        ypd: this.evolucionEnfermeriaForm.value.formCateterDialisis.includes('YPD'),
        ypi: this.evolucionEnfermeriaForm.value.formCateterDialisis.includes('YPI'),
        scd: this.evolucionEnfermeriaForm.value.formCateterDialisis.includes('SCD'),
        sci: this.evolucionEnfermeriaForm.value.formCateterDialisis.includes('SCI'),
        fd: this.evolucionEnfermeriaForm.value.formCateterDialisis.includes('FD'),
        fi: this.evolucionEnfermeriaForm.value.formCateterDialisis.includes('FI'),
        cyscd: this.evolucionEnfermeriaForm.value.formCateterDialisis.includes('CYSCD'),
        cysci: this.evolucionEnfermeriaForm.value.formCateterDialisis.includes('CYSCI'),
      };
      this.evolucionEnfermeriaForm.controls.dataCateterDialisis.setValue(datosCpd);
    }


    const ef = new EnfermeriaEvo(this.evolucionEnfermeriaForm.value);
    
    this.escaparCaracteres(ef);
    this.setDatosGenerales(ef);
    this.evolucionService.enviarEvolucionEnfermeria(ef).subscribe(
      (x) => {
        // console.log(x)
        if (x.ok) {
          this.alertService.success('Evolucion cargada correctamente');
          this.tieneUPP = false;
          this.evolucionEnfermeriaForm.reset({
            toleranciaOral: false,
            tto_vo: false,
            avp: false,
            deambulacion: false,
            heridaQx: false,
            avc: false,
            peritaltismo: false,
            habilitarFechaTraqueotomia: false,
            habilitarFechaARM: false,
            higieneManos: null,
            cabeceraElevada: null,
            controlPresionCuff: null,
            higieneBucal: null,
            aspiracionSubglotica: null,
            listaUPP: [],
            suspensionSedacion:null,
          });
          this.listaUPP = [];
          this.listaUPPmostrar = new MatTableDataSource(this.listaUPP);
          this.loadingEvolucionEnfermeria = false;
        } else {
          console.log(x)
          this.alertService.error("Ocurrio un error, intente nuevamente.");
        }
        
      }, (error) => {
        console.log(error);
        this.alertService.error("Ocurrio un error, intente nuevamente.");
      }
    );
  }

  irAlHistorial() {
    window.open(
      window.location.protocol +
        '//' +
        this.horusUrl +
        '/he_fe/#/login?site=internacion/ver/' +
        this.currentPatient.carpeta,
      '_blank'
    );
  }

  setDatosGenerales(evolucion) {
    evolucion.nombreMedico = JSON.parse(
      sessionStorage.getItem('currentUser')
    ).nombreCompleto;
    evolucion.numeroDocumento = this.currentPatient.dniPaciente;
    evolucion.tipoDocumento = this.currentPatient.tipoDniPaciente;
    evolucion.carpeta = this.currentPatient.carpeta;
  }

  imprimirPaciente(fecha: Moment) {
    this.selectedUrl = this.evolucionService.imprimirReporte(
      this.currentPatient.carpeta,
      fecha.format('MM-DD-YYYY')
    );
    this.selected = true;
    this.fechaSeleccionada = null;
  }

  imprimirReportePorTurno() {
    this.selectedUrl = this.evolucionService.imprimirReportePorTurno(
      this.currentPatient.carpeta,
      this.currentUser.usuario
    );
    this.selected = true;
  }

  imprimirHojaEnfermeria() {
    this.selectedUrl = this.evolucionService.imprimirPaciente(
      this.currentPatient.carpeta
    );
    this.selected = true;
  }

  salirPdf() {
    this.selected = false;
  }

  delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  async submitReg(registroForm: UntypedFormGroup, groupIndex, regIndex) {
    if (registroForm.invalid) {
      console.log('invalido');
      return;
    }
    registroForm.markAsPending();
    const prueba = {
      idCarpeta: this.currentPatient.carpeta,
      numeroDocumento: this.currentPatient.dniPaciente,
      tipoDocumento: this.currentPatient.tipoDniPaciente,
      fechaCreacion: new Date(),
      cantidad: 1,
      enfermero: this.currentUser.usuario,
    };
    registroForm.value.idRegistro = null;
    const frankenstein = Object.assign(prueba, registroForm.value);

    const time = frankenstein.fechaUsuario.split(/\:|\-/g);
    const fecha = new Date();
    if (!registroForm.get('ayer').value) {
      const fechaForm = new Date();
      fechaForm.setHours(time[0], time[1], 0);
     // console.log(fechaForm);
      const fechaPedida = moment(fechaForm);
      const diferencia = moment.duration(moment().diff(fechaPedida));
     // console.log(diferencia.asHours());
      if (diferencia.asHours() < -8 || diferencia.asHours() > 8 ) {
        await this.definirFecha();
      }
    }
    if (frankenstein.ayer || this.esAyer) {
      frankenstein.fechaUsuario = fecha.setDate(fecha.getDate() - 1);
      this.esAyer = false;
    }
    frankenstein.fechaUsuario = fecha;
    frankenstein.fechaUsuario.setHours(time[0], time[1], 0);

    // if (frankenstein.fechaUsuario > new Date()) {
    //   console.log('es mayor');
    // }

    frankenstein.fechaCreacion = this.dateFormat(frankenstein.fechaCreacion);
    frankenstein.fechaUsuario = this.dateFormat(frankenstein.fechaUsuario);


    frankenstein.pieza = this.currentPatient.pieza;
    frankenstein.cama = this.currentPatient.cama;
    //debugger;
    this.medicacionService.registroMedicamento(frankenstein).subscribe(
      (x) => {
        this.medicacionService
          .itemDePedido(frankenstein.numeroPedido, frankenstein.numeroItem)
          .subscribe(
            async (element) => {
              // console.log(x) 
              this.cargarUlt24hsPorMedicamento(frankenstein.practica);
              await this.delay(600);
              let sinUltReg = this.arregloHistorial.filter((obj) => obj.idRegistro !== element.idRegistro);
              if (!sinUltReg.length) {
                sinUltReg = [{fechaUsuario: ''}];
              }
              registroForm.patchValue({
                idRegistro: element.idRegistro,
                numeroPedido: element.numeroPedido,
                numeroItem: element.numeroItem,
                fechaUsuario: this.getFormTime(new Date()),
                nomenclador: element.nomenclador,
                practica: element.practica.codigo,
                descripcion: this.tcPipe.transform(
                  element.practica.descripcion
                ),
                frecuencia: this.tcPipe.transform(
                  element.frecuencia.frecuencia
                ),
                goteo: element.goteo.goteo,
                ultimoRegistro: element.ultimoRegistro,
                ultimoEnfermero: element.enfermero,
                indiAdicional: element.indicacionesAdicionales,
                via: this.tcPipe.transform(element.via.via),
                ult24hs: sinUltReg
              });
            },
            (error) => {
              this.errorConexion2 = true;
              this.dialog.open(DialogMensajeComponent,{
                width: '500px',
                data:{
                  ok: false,
                  mensaje: "Hubo un problema al generar la aplicación de la medicación."
                }
              })
              console.log(error);
            }
          );
      },
      (error) => {
        this.errorConexion = true;
        
        console.log(error);
      }
    );
  }

  deleteReg(idReg, registroForm: UntypedFormGroup) {
    registroForm.markAsPending();
    const prueba = {
      idCarpeta: this.currentPatient.carpeta,
      numeroDocumento: this.currentPatient.dniPaciente,
      tipoDocumento: this.currentPatient.tipoDniPaciente,
      fechaCreacion: new Date(),
      cantidad: 1,
      enfermero: this.currentUser.usuario,
    };

    const frankenstein = Object.assign(prueba, registroForm.value);
    // frankenstein.fechaUsuario = new Date( new Date().toLocaleDateString() + " " + frankenstein.fechaUsuario)
    frankenstein.fechaCreacion = this.dateFormat(frankenstein.fechaCreacion);
    frankenstein.fechaUsuario = frankenstein.ultimoRegistro;
    frankenstein.idRegistro = idReg;
    this.medicacionService.borrarRegistro(frankenstein).subscribe(
      (x) => {
        this.medicacionService
          .itemDePedido(frankenstein.numeroPedido, frankenstein.numeroItem)
          .subscribe(
            async (element) => {
              this.cargarUlt24hsPorMedicamento(frankenstein.practica);
              await this.delay(600);
              let sinUltReg = this.arregloHistorial.filter((obj) => obj.idRegistro !== element.idRegistro);
              if (!sinUltReg.length) {
                sinUltReg = [{fechaUsuario: ''}];
              }
              registroForm.patchValue({
                idRegistro: element.idRegistro,
                numeroPedido: element.numeroPedido,
                numeroItem: element.numeroItem,
                fechaUsuario: this.getFormTime(new Date()),
                nomenclador: element.nomenclador,
                practica: element.practica.codigo,
                descripcion: this.tcPipe.transform(
                  element.practica.descripcion
                ),
                frecuencia: this.tcPipe.transform(
                  element.frecuencia.frecuencia
                ),
                goteo: element.goteo.goteo,
                ultimoRegistro: element.ultimoRegistro,
                ultimoEnfermero: element.enfermero,
                indiAdicional: element.indicacionesAdicionales,
                via: this.tcPipe.transform(element.via.via),
                ult24hs: sinUltReg,
               // ayer: false,
                verUlt24hs: false

              });
            },
            (error) => {
              console.log(error);
            }
          );
      },
      (error) => {
        console.log(error);
      }
    );
  }

  submitRegOut(registroForm: UntypedFormGroup) {
    if (registroForm.invalid) {
     // console.log('invalido');
      return;
    }

    registroForm.markAsPending();
    const frankenstein = {
      idCarpeta: this.currentPatient.carpeta,
      numeroDocumento: this.currentPatient.dniPaciente,
      tipoDocumento: this.currentPatient.tipoDniPaciente,
      fechaCreacion:
        this.dateFormat(new Date()),
      cantidad: 1,
      via: registroForm.value.medicamento.via.via,
      numeroPedido: registroForm.value.medicamento.numeroPedido,
      numeroItem: registroForm.value.medicamento.numeroItem,
      goteo: registroForm.value.medicamento.goteo.goteo,
      frecuencia: registroForm.value.medicamento.frecuencia.frecuencia,
      practica: registroForm.value.medicamento.practica.codigo,
      enfermero: this.currentUser.usuario,
      fechaUsuario:
        this.dateFormat(new Date(registroForm.value.fechaUsuario)),
    };

    console.log(frankenstein);
    debugger
    

    this.medicacionService.registroMedicamento(frankenstein).subscribe(
      (x) => {
        this.medicacionService
          .itemDePedido(frankenstein.numeroPedido, frankenstein.numeroItem)
          .subscribe(
            async (element) => {
              await this.delay(600);
              registroForm.reset();
            },
            (error) => {
              console.log(error);
            }
          );
      },
      (error) => {
        console.log(error);
      }
    );
  }

  cargarConceptosDeBalance() {
    this.loadingConceptos = true;
    this.balanceService.cargarConceptosBalance().subscribe(
      (data) => {
        // this.conceptos = data.sort((a, b) => a.idConcepto - b.idConcepto);
        this.conceptos = data;
        this.conceptos.forEach((concepto) =>
          this.cargarRegistrosDeConcepto(concepto, this.currentPatient.carpeta)
        );
        this.conceptosIngresos = data.filter((x) => x.modo === 'ingreso');
        this.conceptosEgresos = data.filter((x) => x.modo === 'egreso');
        this.loadingConceptos = false;
      },
      (error) => {
        this.loadingConceptos = false;
        console.log(error);
      }
    );
  }

  //Funcion que ejecuta el slide toggle 
  changed(){
    if(this.tieneUPP){
      //Activo los input
      this.uppForm.controls.zona.enable();
      this.uppForm.controls.grado.enable();
      this.uppForm.controls.riesgo.enable();
      this.evolucionEnfermeriaForm.controls.upp.setValue(true);
    } else {
      //Limpio los input y los deshabilito 
      this.otraZona = '';
      this.otroValor = false;
      this.uppForm.controls.zona.disable();
      this.uppForm.controls.zona.setValue('');
      this.uppForm.controls.grado.disable();
      this.uppForm.controls.grado.setValue('');
      this.uppForm.controls.riesgo.disable();
      this.uppForm.controls.riesgo.setValue('');
      this.evolucionEnfermeriaForm.controls.upp.setValue(false);
      this.listaUPP = [];
    }
  }

  back(){    
    this.router.navigateByUrl('/internados');
  }
  

  cargarRegistrosDeConcepto(concepto, carpeta) {
    concepto.loading = true;
    this.balanceService.cargarRegistrosDeConcepto(concepto, carpeta).subscribe(
      (x) => {
        concepto.registros = x;

        concepto.loading = false;
      },
      (error) => {
        concepto.loading = false;
        console.log(error);
      }
    );
  }

  async borrarRegistroDeConcepto(registro) {
    await this.balanceService.borrarRegistro(registro).subscribe(
      (x) => {},
      (error) => {
        console.log(error);
      }
    );
    this.cargarRegistrosDeConcepto(
      this.conceptos.find(
        (concepto) => concepto.idConcepto === registro.concepto.idConcepto
      ),
      this.currentPatient.carpeta
    );
  }

  onlyHora(fecha) {
    return fecha.substr(fecha.indexOf(' ') + 1);
  }

  totalDeConcepto(concepto) {
    if (concepto.registros.length > 0) {
      return concepto.registros.reduce(
        (accumulator, currentValue) => accumulator + currentValue.volumen,
        0
      );
    } else {
      return 0;
    }
  }

  totalDeIngresos() {
    return this.conceptosIngresos.reduce(
      (acc, currentValue) => acc + this.totalDeConcepto(currentValue),
      0
    );
  }

  totalDeEgresos() {
    return this.conceptosEgresos.reduce(
      (acc, currentValue) => acc + this.totalDeConcepto(currentValue),
      0
    );
  }
  async definirFecha() {
   return new Promise((resolve) => {
    this.dialog
   .open(DialogConfirmacionComponent, {
     data: `¿La indicación del suministro corresponde al día de ayer?`,
   })
   .afterClosed()
   .subscribe((confirmado: boolean) => {
     if (confirmado) {
       this.esAyer = confirmado;
      // console.log('si es de ayer');
     }
     resolve(1);
    });
  });
  }

  //Zonas del upp, chequeo y agregado de una nueva zona
  agregarZona(valor){
  this.listaZonaAux.push({value: valor});
  this.listaZonas.push.apply(this.listaZonas,{value: valor});
  this.uppForm.get('zona').setValue(valor);
  sessionStorage.setItem('zonaIngresada',valor);
  
  //  console.log(this.evolucionEnfermeriaForm.get('zona'))
  
  }
  chequeoValor(valor){
    // console.log(valor.value)
    if(valor.value == 'Otros'){
      this.zonaAux = valor.value;
      sessionStorage.setItem('otraZona',this.zonaAux.toString());
      this.otroValor = true;
    }
    else{
      this.otroValor = false;
    }
    
  }

  guardarUpp() {
    if (! this.uppForm.invalid) {
      if ((! this.otroValor) || (this.otroValor && this.otraZona)) {
        const upp = {
          zona: this.uppForm.controls.zona.value,
          grado: this.uppForm.controls.grado.value,
          riesgo: this.uppForm.controls.riesgo.value,
          borrado: false,
        }
        this.listaUPP.push(upp);
        this.uppForm.controls.zona.setValue('');
        this.uppForm.controls.grado.setValue('');
        this.uppForm.controls.riesgo.setValue('');
        if (this.otroValor) {
          this.otroValor = false;
          this.otraZona = ''; 
        }
        this.listaUPPmostrar = new MatTableDataSource(this.listaUPP);
        this.uppForm.reset();
      }
      
    }
    else {
      return;
    }

  }

  eliminarUpp(upp) {
    let pos = this.listaUPP.indexOf(upp);
    this.listaUPP.splice(pos, 1);
    this.listaUPPmostrar = new MatTableDataSource(this.listaUPP);
  }

  changeAvc() {
    if (! this.evolucionEnfermeriaForm.value.avc) {
      this.evolucionEnfermeriaForm.controls.formAvc.setValue([]);
      this.evolucionEnfermeriaForm.controls.formAvc.setValidators(Validators.nullValidator);
    }
    else {
      this.evolucionEnfermeriaForm.controls.formAvc.setValidators(Validators.required);
    }
    this.evolucionEnfermeriaForm.controls.formAvc.updateValueAndValidity();
  }

  changeCpd() {
      if (! this.evolucionEnfermeriaForm.value.cateterDialisis) {
        this.evolucionEnfermeriaForm.controls.formCateterDialisis.setValue([]);
        this.evolucionEnfermeriaForm.controls.formCateterDialisis.setValidators(Validators.nullValidator);
      }
      else {
        this.evolucionEnfermeriaForm.controls.formCateterDialisis.setValidators(Validators.required);
      }
      this.evolucionEnfermeriaForm.controls.formCateterDialisis.updateValueAndValidity();
  }

  cargarEstadoIngresoHabitacion() {
    this.internacionService.getCirugiasPorCarpetaDeHoy(this.currentPatient.carpeta).subscribe(
      (datos) => {
        if (datos.ok) {
          this.cirugiasHoy = datos.elementos;
          this.tieneEstadoIngresoHabitacion = true;
          this.estadoIngresoHabitacion = this.cirugiasHoy[0].ingresoHabitacion;
        }
        else {
          this.tieneEstadoIngresoHabitacion = false;
        }
      },
      (error) => {
        console.log(error);
        this.tieneEstadoIngresoHabitacion = false;
      }
    )
  }

  guardarEstadoIngreso() {
    this.cargandoIngreso = true;
    if (this.estadoIngresoHabitacion == 'APROBADO') {
      this.cirugiasHoy.map(cir => cir.ingresoHabitacion = 'PENDIENTE');
    }
    else if (this.estadoIngresoHabitacion == 'PENDIENTE') {
      this.cirugiasHoy.map(cir => cir.ingresoHabitacion = 'APROBADO')
    }
    this.internacionService.setIngresoHabitacionCirugia(this.cirugiasHoy).subscribe(
      (info) =>{
        if (info.ok) {
          if (this.estadoIngresoHabitacion == 'APROBADO') {
            this.estadoIngresoHabitacion = 'PENDIENTE';
          }
          else if (this.estadoIngresoHabitacion == 'PENDIENTE') {
            this.estadoIngresoHabitacion = 'APROBADO';
          }
          this.cargandoIngreso = false;
        }
      },
      (error) => {
        console.log(error);
      }
    )
  }
 

}
